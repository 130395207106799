<template>
  <div class="page">
    <Navbar :title="'礼包'"/>
    <div class="header">
      <Tabbar :tab='tab' @change="tabChange" />
    </div>
    <div class="page_list" :style="`width: 200%; transform: translateX(${25 - tabIndex * 50}%);`">
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key='index'
          class="list_item">
          <div class="list_item_name">{{item.name}}</div>
          <div class="list_item_text">元宝*{{item.val1}}、积分*{{item.val2}}、礼盒*{{item.val2}}</div>
          <div class="list_item_text">礼包剩余：<span class="list_item_val">{{item.num}}</span></div>
          <div class="list_item_gift">
            <div class="list_item_text">截至时间：{{item.date}}</div>
            <div @click="getGift(index)" class="list_item_btn" :class="{'list_item_btn_disbg': item.state == 2 || item.state == 3}">{{getItemBtnText(item.state)}}</div>
          </div>
        </div>
        <NoData v-if="list.length > 0&&listNoMore" title="暂无更多礼包~" />
        <NoData v-if="list.length == 0" title="暂无礼包~" />
      </div>
      <div class="list">
        <div
          v-for="(item, index) in list2"
          :key='index'
          class="list_item">
          <div class="list_item_name">{{item.name}}</div>
          <div class="list_item_text">元宝*{{item.val1}}、积分*{{item.val2}}、礼盒*{{item.val2}}</div>
          <div class="list_item_text">过期时间：{{item.date}}</div>
          <div class="list_item_line"></div>
          <div class="list_item_gift" :class="{'list_item_gift_dis': item.state == 4}">
            <div class="list_item_code" >
              <SvgIcon name="gift" class="list_item_code_svg" :color="$config.primary_color" />
              礼包码：<span>{{item.code}}</span>
            </div>
            <div @click="copyCode(item)" class="list_item_btn list_item_btn2">复制</div>
          </div>
        </div>
        <NoData v-if="list2.length > 0&&listNoMore2" title="暂无更多礼包~" />
        <NoData v-if="list2.length == 0" title="暂无礼包~" />
      </div>
    </div>
  </div>
</template>
<script>
import Tabbar from '@/components/tabbar.vue'
export default {
  components: {
    Tabbar
  },
  data(){
    return {
      tabIndex: 0,
      tab: [
        {name: '全部礼包', check: true},
        {name: '我的礼包', check: false}
      ],
      list: [ // 可领取
        // state 1 领取 2 已领取 3 已抢光 4 过期
        {name: '新人礼包', state: 1, code: '23355', date: '2029-01-02 ', num: '80%', val1: 12, val2: 120, val3: 1230},
        {name: '元宵礼包', state: 2, code: '23355', date: '2029-01-02 ', num: '23%', val1: 12, val2: 120, val3: 1230},
        {name: '春节礼包', state: 3, code: '12362', date: '2029-01-02 ', num: '0%', val1: 12, val2: 120, val3: 1230},
        {name: '专属礼包', state: 1, code: '44532', date: '2029-01-02 ', num: '50%', val1: 12, val2: 120, val3: 1230},
      ],
      listNoMore: true, // 是否还有更多
      list2: [ // 可使用
        {name: '新人礼包2', state: 1, code: '23355', date: '2029-01-02 ', num: '80%', val1: 12, val2: 120, val3: 1230},
        {name: '元宵礼包2', state: 4, code: '12345', date: '2029-01-02 ', num: '23%', val1: 12, val2: 120, val3: 1230},
      ],
      listNoMore2: true, // 是否还有更多
    }
  },
  methods: {
    getItemBtnText (key) {
      let arr = ['领取', '已领取', '已抢光']
      return arr[key - 1]
    },
    getGift (key) {
      if (this.list[key].state !== 1) {
        return
      }
      this.$Msg('你已经领取该礼包，请去我的礼包查看详情')
      this.list[key].state = 2
      this.list2.push(this.list[key])
      // this.list.splice(key, 1)
    },
    copyCode (item) {
      if (item.state == 4) {
        this.$Msg('该礼包已失效')
        return
      }
      this.$utils.copyHandle(item.code)
      this.$Msg('复制成功')
    },
    tabChange (e) {
      this.tabIndex = e
    },
  }
}
</script>
<style scoped lang="scss">
.page{
  background-color: #f0f0f0;
}
.header{
  width: 100%;
  background-color: #fff;
}
.page_list{
  display: flex;
  overflow: hidden;
  transition: .3s;
  flex: 1;
}
.list{
  padding: .3rem .2rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.list_item{
  margin-bottom: .15rem;
  padding: 0 .15rem;
  width: 100%;
  background-color: #fff;
  border-radius: .15rem;
  box-sizing: border-box;
}
.list_item_name{
  padding-top: .2rem;
  padding-bottom: .1rem;
  font-size: .3rem;
  font-weight: bold;
}
.list_item_text{
  padding: .05rem 0;
  font-size: .23rem;
}
.list_item_line{
  margin: .2rem 0;
  flex: 1;
  height: 1px;
  background: linear-gradient(to right, #ddd, #ddd 5px, transparent 5px, transparent);
  background-size: 15px 100%;
}
.list_item_gift{
  display: flex;
  justify-content: space-between;
  padding-bottom: .1rem;
  width: 100%;
}
.list_item_gift_dis{
  filter: grayscale(100%);
}
.list_item_val{
  color: $color-primary;
  font-weight: bold;
}
.list_item_btn{
  width: 1.1rem;
  line-height: .4rem;
  text-align: center;
  font-size: .22rem;
  background-color: $color-primary;
  color: #fff;
  border-radius: .2rem;
}
.list_item_btn2{
  border: 1px solid $color-primary;
  background-color: transparent;
  color: $color-primary;
  box-sizing: border-box;
}
.list_item_btn_disbg{
  background-color: $color-default;
}
.list_item_code{
  flex: 1;
  display: flex;
  align-items: center;
  color: $color-primary;
  font-size: .25rem;
}
.list_item_code span{
  font-weight: bold;
}
.list_item_code_svg{
  margin-right: .1rem;
  width: .4rem;
  height: .4rem;
}
</style>